import React from 'react';
import { NavLink, useLocation, useNavigate, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { Nav, NavLink as ReactstrapNavLink } from 'reactstrap';
import { BackgroundColorContext } from 'contexts/BackgroundColorContext';
import { useAuth } from '../../hooks/useAuth';
import { useLoginStore } from '../../stores/loginStore';
import { routes } from 'services/apiFetches';
var ps;

function Sidebar(props) {
  const location = useLocation();
  const { logoutHandler } = useAuth();
  const navigate = useNavigate();
  const { loggedUser, setActiveLink } = useLoginStore();
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? 'active' : '';
  };
  
  if (!loggedUser) return null;
  let routesToRender = loggedUser.permissions.includes('admin')
    ? routes
    : loggedUser.permissions;
  const keyPermissions = {
    Oldelval: 'tim-icons icon-chart-pie-36 p-right1',
    Siemens: 'tim-icons icon-bag-16 p-right1',
    BTU: 'tim-icons icon-bank p-right1',
    admin: 'tim-icons icon-single-02 p-right1',
    YPF: 'tim-icons icon-components p-right1',
  };

  // im forced to do this idiotic thing with the anchor, why would you ONLY APPLY A CLASS STYLE TO A SPECIFIC HTML ELEMENT, LIKE WHY??? this template is dog water
  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="sidebar" data={color}>
          <Link to="#">
            <div className="logo">
              <a className="simple-text logo-mini" target="_self">
                <div class="logo-img">
                  <img
                    src="https://agiliza-sa.com/Src/assets/isologoweb.webp"
                    alt="react-logo"
                  />
                </div>
              </a>
              <span class="simple-text logo-normal" target="_self">
                {loggedUser.name}
              </span>
            </div>
          </Link>
          <Nav>
            {routesToRender.map((permission) => {
              let navLink;
              if (permission !== 'admin') {
                navLink = (
                  <NavLink to={'/'} className="text-xl">
                    <i className={keyPermissions[permission]} />
                    <p className="lead">{permission}</p>
                  </NavLink>
                );
              } else {
                navLink = (
                  <NavLink to={'/admin/users'} className="text-xl">
                    <i className={keyPermissions[permission]} />
                    <p className="lead">Gestión de usuarios</p>
                  </NavLink>
                );
              }

              return (
                <li
                  key={permission}
                  className={activeRoute('/' + permission)}
                  onClick={() => {
                    if (permission === 'admin') return;
                    setActiveLink(permission);
                  }}
                >
                  {navLink}
                </li>
              );
            })}
            <li className="active-pro">
              <ReactstrapNavLink
                onClick={() => {
                  logoutHandler().then((loggedOut) => {
                    if (loggedOut) {
                      navigate('/login');
                    }
                  });
                }}
              >
                <i className="tim-icons icon-button-power" />
                <p>Salir</p>
              </ReactstrapNavLink>
            </li>
          </Nav>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

Sidebar.propTypes = {
  // if true, then instead of the routerAdmin[i].name, routerAdmin[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
